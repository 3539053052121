import React from 'react';
import styled from '@emotion/styled';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import topBg from './assets/images/fob-top-bg.png';
import topBgFull from './assets/images/fob-top-bg.png';

const Root = styled.div`
  position: absolute;
  background-position:top center, top center;
  background-size: 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  pointer-events: none;
  backkground-color:#fff;
}
`;
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
      <Root></Root>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
